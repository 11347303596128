package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.launch
import com.lightningkite.kiteui.navigation.screenNavigator
import com.picme.*
import com.picme.components.*
import com.picme.session


@Routable("account")
object ProfileScreen : PicmeDialog() {
    override val title = Constant("Account")
    override fun ViewWriter.content() {
        scrolls - padded - col {
            val editName = Property("")
            val email = Property("")
            val profileImage = Property(ImageUploadInfo())

            formCol {
                launch {
                    session.awaitNotNull().authentication.getMyUserInfo().let {
                        profileImage set ImageUploadInfo(original = it.profilePicture.imageIfExists())
                        editName set it.userData.name
                        email set it.userData.email
                    }
                }
                styledTextField {
                    ::exists { email() != "" }
                    content bind editName
                    label = "Name"
                    hint = "Name"
                }

                styledTextField {
                    ::exists { email() != "" }
                    field.enabled = false
                    content bind email
                    label = "Email"
                    hint = "Email"
                }

                sizeConstraints(minHeight = 12.rem) - centered - col {
                    ::exists { email() == "" }
                    centered - activityIndicator()
                }

                space()
                col {
                    spacing = 0.2.rem
                    h4("Profile image")
                    spacing = 1.rem

                    suspend fun uploadImage() = ExternalServices.requestFile(listOf("image/*")).let {
                        if (it != null) {
                            profileImage set profileImage().copy(newImage = it, deleteOriginal = false)
                        }
                    }
                    row {
                        sizeConstraints(width = 7.rem, height = 7.rem) - centered - ThemeDerivation {
                            it.copy(
                                cornerRadii = CornerRadius.ForceConstant(100.rem),
                                background = Color.gray.applyAlpha(0.4f)
                            ).withBack
                        }.onNext - unpadded - stack {
                            unpadded - image {
                                scaleType = ImageScaleType.Crop
                                ::source {
                                    if (profileImage().deleteOriginal) null
                                    else profileImage().newImage?.let(::ImageLocal) ?: profileImage().original
                                }
                            }
                        }
                        centered - tweakTheme {
                            it.copy(
                                foreground = Color.picmeBlue,
                                cornerRadii = CornerRadius.ForceConstant(2.rem),
                                spacing = 0.2.rem
                            )
                        } - col {
                            imageActionButton("Upload image", onClick = ::uploadImage)
                            imageActionButton(
                                "Reset",
                                exists = shared { (profileImage().original != null || profileImage().newImage != null) && !profileImage().deleteOriginal }
                            ) {
                                profileImage set profileImage().copy(deleteOriginal = true)
                            }
                        }
                    }
                }
            }
            actionOrCancelSection(
                onCancel = { screenNavigator.dismiss() },
                actionButton = {
                    importantButton(
                        text = "Save",
                        enabled = shared {
                            editName() != session()?.authentication?.getMyUserInfo()?.userData?.name || profileImage().newImage != null || profileImage().deleteOriginal
                        }) {
                        if (editName() != session()?.authenticatedUser()?.name) {
                            session.awaitNotNull().authentication.setUserAttributes(
                                setTosRead = true,
                                name = editName(),
                            )
                        }
                        if (profileImage().deleteOriginal) {
                            session.awaitNotNull().authentication.deleteUserProfilePicture()
                        }

                        profileImage().newImage?.let {
                            session.awaitNotNull().authentication.putProfilePicture(it)
                        }
                        navigator.dismiss()
                    }
                }
            )


            sizeConstraints(height = 1.rem) - space()
            centered - compact - danger - button {
                text { content = "Delete account" }
                onClick {
                    dialogScreenNavigator.navigate(DeleteAccount)
                }
            }

            sizeConstraints(height = 1.rem) - space()
            centered - row {
                blueLink - externalLink {
                    text("Terms of Use")
                    to = "https://www.picme.com/terms-of-use.html"
                    newTab = true
                }
                blueLink - externalLink {
                    text("Privacy Policy")
                    to = "https://www.picme.com/privacy-policy.html"
                    newTab = true
                }
            }
        }
    }
}