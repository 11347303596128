package com.picme.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import com.picme.views.*


fun ViewWriter.collectionActions(
    collectionId: Readable<CollectionId>,
    preferredPopoverDirection: PopoverPreferredDirection,
    setView: suspend (v: CollSubPage) -> Unit
) {
    centered - iconButtonTheme - menuButton {
        centered - icon(PIcon.more, "More Horiz")
        preferredDirection = preferredPopoverDirection
        requireClick = true
        opensMenu {
            popoverWrapper(width = 20.rem) {

                launch {
                    CollectionState.toggleView(CollSubPage.Main)
                }
                val isOwner = shared {
                    ownsCollection(
                        session.awaitNotNull().collection2.getCollectionLive(collectionId())()
                    )
                }
                col {
                    popoverItem {
                        ::exists {
                            session.awaitNotNull().collection2.listUploadsLive(collectionId()).all().isNotEmpty()
                        }
                        icon.source = PIcon.download
                        text.content = "Download all items"
                        button.onClick {
                            val message = when (Platform.current) {
                                Platform.Web -> "All items will be downloaded to your device as a zip file."
                                Platform.iOS -> "All items will be saved to your Camera Roll."
                                else -> "All items will be saved to your device."
                            }
                            closePopovers()
                            showConfirmDialog(
                                title = "Confirm Download",
                                confirmLabel = "Download",
                                content = message,
                                onConfirm = {
                                    collectionDownload(collectionId())
                                }
                            )
                        }
                    }

                    toggleGridSizePopover()

                    popoverItem {
                        icon.source = PIcon.refresh
                        text.content = "Refresh"
                        button.onClick {
                            closePopovers()
                            forceRefresh()
                        }
                    }

                    separatorTheme - separator()

                    popoverItem {
                        ::exists { !isOwner() }
                        icon.source = PIcon.unlink
                        text.content = "Disconnect"
                        button.onClick {
                            closePopovers()
                            showConfirmDialog(
                                title = "Disconnect Collection",
                                content = "Are you sure you want to disconnect from this collection?",
                                warning = "This action cannot be undone.",
                                confirmLabel = "Disconnect",
                                onConfirm = {
                                    session.awaitNotNull().collection2.revokeRights(
                                        collectionId = collectionId(),
                                        userId = session.awaitNotNull().authenticatedUser().userId
                                    )
                                    navigateToCollOrLanding(notThisCollection = collectionId())
                                }
                            )
                        }
                    }

                    popoverItem {
                        ::exists { isOwner() }
                        icon.source = PIcon.deletetrash
                        text.content = "Delete all items"
                        button.onClick {
                            closePopovers()
                            dialogGeneric { close ->
                                h4 {
                                    content = "Confirm Delete"
                                    align = Align.Center
                                }
                                text("All items will be deleted from the collection.")
                                actionOrCancelSection(
                                    onCancel = { close() },
                                    actionButton = {
                                        importantButton("Delete") {
                                            session.awaitNotNull().collection2.deleteAllUploads(collectionId())
                                            close()
                                            showToast("Successfully moved images to trash")
                                        }
                                    }
                                )
                            }
                        }
                    }
                    popoverItem {
                        ::exists { isOwner() }
                        icon.source = PIcon.viewtrash
                        text.content = "View Trash"
                        button.onClick {
                            closePopovers()
                            setView(CollSubPage.Trash)
                        }
                    }
                }
            }
        }
    }
}


fun ViewWriter.toggleGridSizePopover() = popoverItem {
    icon::source {
        when (getGridSize()) {
            GridSize.Small -> PIcon.expand
            GridSize.Large -> PIcon.contract
        }
    }
    text::content {
        when (getGridSize()) {
            GridSize.Small -> "Large grid size"
            GridSize.Large -> "Small grid size"
        }
    }
    button.onClick {
        closePopovers()
        toggleGridSize()
    }
}
