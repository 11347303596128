package com.picme.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.sub
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.*
import com.picme.sdk2.generated.AdSize
import com.picme.sdk2.generated.CollectionId


interface ProgressHandle {
    var individualItemProgress: Float
    var itemCount: Int
    var currentIndex: Int
    var image: ImageSource
}

fun ViewWriter.openProgressModal(
    title: String,
    execute: suspend ProgressHandle.() -> Unit,
    onComplete: suspend () -> Unit
) {
    val overlayStack = overlayStack ?: return

    val currentItemProgress = Property(0f)
    val currentItemIndex = Property(0)
    val itemCount = Property(1)
    val currentImage = Property<ImageSource>(Resources.picmelogo)

    var cancellable: Cancellable? = null
    val gate = WaitGate()
    gate.permit = false

    val screen = with(overlayStack) {
        dismissBackgroundTheme
        dismissBackground {
            onClick { }

            padded - stack {
                centered - sizeConstraints(width = 24.rem) - FatCardSemantic.onNext - col {
                    row {
                        sizeConstraints(
                            width = 6.rem,
                            height = 6.rem
                        ) - image {
                            scaleType = ImageScaleType.Crop
                            ::source { currentImage() }
                        }
                        expanding - col {
                            val secondsSinceStart = Property(0)
                            sub().launch {
                                while (true) {
                                    delay(1000)
                                    secondsSinceStart.set(secondsSinceStart() + 1)
                                }
                            }
                            row {
                                spacing = 0.3.rem
                                bold - text { content = title }
                                bold - text { ::exists { secondsSinceStart() % 4 > 0 }; content = "." }
                                bold - text { ::exists { secondsSinceStart() % 4 > 1 }; content = "." }
                                bold - text { ::exists { secondsSinceStart() % 4 > 2 }; content = "." }
                            }
                            atEnd - buttonTheme - compact - button {
                                row {
                                    space()
                                    text("cancel")
                                    space()
                                }
                                onClick {
                                    cancellable?.cancel()
                                }
                            }
                            col {
                                spacing = 0.px
                                subtext {
                                    ::content {
                                        if (itemCount() == 1) currentItemProgress().times(100).toInt().toString()
                                            .plus("%")
                                        else "${currentItemIndex().plus(1)} of ${itemCount()}"
                                    }
                                }
                                sizeConstraints(height = 14.dp) - progressBar {
                                    themeChoice += ThemeDerivation {
                                        it.copy(
                                            foreground = Color.picmePurple,
                                            background = Color.gray.applyAlpha(0.5f),
                                            cornerRadii = CornerRadius.ForceConstant(0.5.rem)
                                        ).withBack
                                    }
                                    ::ratio {
                                        currentItemIndex().plus(currentItemProgress())
                                            .div(itemCount()).times(0.95f).plus(0.05f)
                                    }
                                }
                            }
                        }
                    }
                    col {
                        ad(gate)
                    }
                }
            }
        }
    }

    cancellable = launchGlobal {
        try {
            object : ProgressHandle {
                override var individualItemProgress: Float by currentItemProgress
                override var itemCount: Int by itemCount
                override var currentIndex: Int by currentItemIndex
                override var image: ImageSource by currentImage

            }.execute()
            gate.await()
        } finally {
            asyncGlobal { onComplete() }
            overlayStack.removeChild(screen)
        }
    }
}


fun ViewWriter.ad(gate: WaitGate) {
    sizeConstraints(width = 22.rem, height = 222.dp) - image {
        scaleType = ImageScaleType.Crop
        naturalSize = true
        source = Resources.picMePaigeAdHorizontal
        sub().launch {
            while (true) {
                source = getAd()
                delay(3_000)
                gate.permit = true
                gate.permit = false
                delay(100)
            }
        }
    }
}


suspend fun getAd(): ImageSource = try {
    session()?.ad?.adServe(
        collectionId = currentCollection()?.collectionId?.raw,
        adSize = AdSize.Small,
        contextId = null,
        unitTest = false
    ) ?: Resources.picMePaigeAdHorizontal
} catch (e: Exception) {
    e.printStackTrace2()
    Resources.picMePaigeAdHorizontal
}

