package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.actuals.deviceCanShareFiles
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.collection2.GetUploadResponse2
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.PatchUploadBody
import com.picme.sdk2.generated.collection2.UploadId


fun Stack.imageViewPagerOverlay(
    imagesWithAds: Readable<MutableList<ImageDisplayInfo>>,
    curr: Readable<RecyclableInfo>,
    collection: Readable<PCollection>
) {
    val currImage = shared { imagesWithAds().first { it.id == curr().id } }
    val caption = Property("")
    val dirtyCaption = Property("")


    val isAd = shared { curr().id == adUploadId }

    val details = shared {
        if (currImage().id == adUploadId) null
        else session.awaitNotNull().collection2.getUploadLive(
            collectionId = currentCollection()?.collectionId!!,
            uploadId = currImage().id.let(::UploadId)
        )()
    }

    suspend fun deleteImage() {
        val isLast =
            imagesWithAds().indexOfFirst { it.id == curr().id } == imagesWithAds().size - 1
        deleteImages(
            collection().images().all().filter { it.uploadId.raw == currImage().id }
        )
        if (isLast) dialogScreenNavigator.dismiss()
    }

    val showBottomActions = shared { isSmallScreen() && !isAd() }
    val hasCaption = shared { details()?.upload?.caption?.raw?.isNotBlank() ?: false }

    expanding - gravity(
        Align.Stretch,
        Align.Start
    ) - bar - compact - unpadded - col {
        spacing = 0.dp
        expanding - padded - sizeConstraints(minHeight = 72.dp) - row {
            reactiveScope {
                caption set (details()?.upload?.caption?.raw ?: "")
                dirtyCaption set (details()?.upload?.caption?.raw ?: "")
            }
            centered - actionButtonNoText(PIcon.chevronleft) { dialogScreenNavigator.dismiss() }
            space {} in weight(1f)

            gravity(Align.End, Align.Center) - h4 {
                ::exists { isAd() }
                content = "Advertisement"
            }
            padded - row {
                onlyWhen { !showBottomActions() && !isAd() } - imageActions(
                    false,
                    currImage,
                    details,
                    ::deleteImage
                )
                col {
                    spacing = 0.dp
                    ::exists { !isAd() }
                    atEnd - row {
                        spacing = 4.dp
                        text("Uploaded")
                        bold - text { ::content { formatIsoDate(details()?.upload?.uploadTime?.toString()) } }
                    }
                    atEnd - row {
                        ::exists { details()?.uploader?.uploaderName?.isNotBlank() ?: false }
                        spacing = 4.dp
                        text("by")
                        bold - text { ::content { details()?.uploader?.uploaderName ?: "" } }
                    }
                }
                iconButtonTheme - button {
                    ::exists { !hasCaption() && !isAd() }
                    exists = false
                    smallIcon(PIcon.caption)
                    onClick {
                        editCaption(caption, dirtyCaption, currImage)
                    }
                }
            }
        }
        unpadded - separatorTheme - separator()
        row {
            ::exists { hasCaption() }
            expanding - button {
                centered - h4 {
                    themeChoice += ThemeDerivation {
                        it.copy(id = "not-bold", font = it.font.copy(bold = false)).withoutBack
                    }
                    ::content{ details()?.upload?.caption?.raw ?: "" }
                    align = Align.Center
                }
                atEnd - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - icon {
                    source = Icon.edit
                }
                onClick {
                    editCaption(caption, dirtyCaption, currImage)
                }
            }
        }
        unpadded - separatorTheme - separator {
            ::exists { hasCaption() }
        }
    }


    atBottom - unpadded - stack {
        spacing = 0.dp
        bar - unpadded - col {
            spacing = 0.dp
            atTop - unpadded - separatorTheme - separator() {
                ::exists { showBottomActions() }
            }


            onlyWhen { showBottomActions() } - padded - imageActions(
                true,
                currImage,
                details,
                ::deleteImage
            )
        }
    }
}

fun ViewWriter.editCaption(
    caption: Property<String>,
    captionDirty: Property<String>,
    currImage: Readable<ImageDisplayInfo>
) {
    dialogGeneric { close ->
        val captionIsNotEmpty = caption.value.isNotBlank()
        h4 {
            content = "Enter a title"
            align = Align.Center
        }
        val tf: TextField;
        styledTextField {
            tf = this.field
            content bind captionDirty
            hint = "title"
        }
        actionOrCancelSection(
            onCancel = close,
            actionButton = {
                importantButton("Save", onClick = {
                    session()?.collection2?.patchUpload(
                        collectionId = currentCollection()!!.collectionId,
                        uploadId = currImage().id.let(::UploadId),
                        body = PatchUploadBody(caption = Retainable(captionDirty()))
                    )
                    caption set captionDirty()
                    close()
                })
            },
        )
        centered - blueLink - button {
            text("Clear")
            exists = captionIsNotEmpty
            onClick {
                session()?.collection2?.patchUpload(
                    collectionId = currentCollection()!!.collectionId,
                    uploadId = currImage().id.let(::UploadId),
                    body = PatchUploadBody(caption = Retainable(""))
                )
                caption set ""
                captionDirty set ""
                close()
            }
        }
        launch { tf.requestFocus() }
    }
}

fun ViewWriter.imageActions(
    atBottom: Boolean,
    currImage: Readable<ImageDisplayInfo>,
    details: Readable<GetUploadResponse2?>,
    confirmDelete: suspend () -> Unit,
) {
    row {
        centered - actionButtonNoText(PIcon.trash) {
            showConfirmDialog(
                title = "Confirm Delete",
                content = "This item will be deleted from the collection.",
                confirmLabel = "Delete",
                onConfirm = confirmDelete
            )
        }
        space(multiplier = 2.0) { exists = !atBottom }

        expanding - stack {
            centered - actionButtonNoText(PIcon.download) {
                val imageToDownload = currImage()
                openProgressModal(
                    title = "Downloading file",
                    execute = {
                        launchGlobal {
                            image = imageToDownload.thumbnail
                            try {
                                individualItemProgress = 0.25f
                                val filename = imageToDownload.id.validDownloadableName()
                                val url = getDetailsUri(imageToDownload.id.let(::UploadId))
                                ExternalServices.download(filename, url, DownloadLocation.Pictures) {
                                    individualItemProgress = it
                                }
                                individualItemProgress = 0.5f
                                delay(1000)
                                individualItemProgress = 0.75f
                                delay(1000)

                            } catch (e: Exception) {
                                e.printStackTrace()
                                showToast("Download failed. Check PicMe's Camera Roll access in Settings.")
                            }
                        }

                    }, onComplete = {
                        showToast(
                            when (Platform.current) {
                                Platform.iOS -> "File saved to Camera Roll"
                                else -> "File downloaded"
                            }
                        )
                    }
                )
            }
        }
        space(multiplier = 2.0) { exists = !atBottom && deviceCanShareFiles() }
        expanding - stack {
            exists = deviceCanShareFiles()
            centered - actionButtonNoText(PIcon.share) {
                val copy = getBlob(details()?.getDetailsUri ?: "")
                ExternalServices.share(listOf(currImage().id.validDownloadableName() to copy))
            }
        }

        space(multiplier = 2.0) { exists = !atBottom }

        centered - iconButtonTheme - menuButton {
            ::exists { currentCollection()?.let { ownsPCollection(it) } ?: false }
            icon(PIcon.more, "More Actions")
            preferredDirection =
                if (atBottom) PopoverPreferredDirection.aboveLeft else PopoverPreferredDirection.belowLeft
            requireClick = true
            opensMenu {
                popoverWrapper {
                    col {
                        popoverItem {
                            icon.source = PIcon.copy
                            text.content = "Copy to another collection"
                            button.onClick {
                                openCopyMenu(listOf(UploadId(currImage().id)))
                                closePopovers()
                            }
                        }
                        popoverItem {
                            icon.source = PIcon.image
                            text.content = "Use as Collection Image"
                            button.onClick {
                                val uri = details()?.getDetailsUri ?: return@onClick
                                val copy = getBlob(uri)
                                val coll = currentCollection() ?: return@onClick
                                session()!!.collection2.putCollectionCoverPhoto(
                                    collectionId = coll.collectionId,
                                    body = RequestBodyBlob(content = copy),
                                    tempUri = uri,
                                    onProgress = {}
                                )
                                closePopovers()
                            }
                        }
                    }
                }
            }
        }

        space(multiplier = 2.0) { exists = !atBottom }
    }
}

suspend fun getBlob(url: String): Blob {
//    https://stackoverflow.com/questions/65191708/chrome-browser-and-cors-issue-when-caching-present-in-request-headers
    return com.lightningkite.kiteui.fetch(
        url, method = HttpMethod.GET,
        httpHeaders().apply {
            append("mode", "cors")
            append("cache", "no-cache")
            append("Cache-Control", "no-cache")
        },
    ).blob()
}