package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.CornerRadius
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.emailOrPhone
import com.picme.session
import com.picme.sdk2.unauthApi


@Routable("create-account")
object CreateAccount : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        val password = Property("")
        val check = Property(false)
        val passwordError: Property<String?> = Property(null)
        authScreen("Create new account?", Constant(true)) {
            subtext("We couldn't find an account with that email. To create an account, enter a password and click Continue for a verification code.")

            styledTextField {
                label = "Email or Phone"
                hint = "Email or Phone"
                content bind emailOrPhone
                field.keyboardHints = KeyboardHints.email
            }

            onlyWhen(false) { emailOrPhone.await().isPhone() } - row {
                checkbox {
                    checked bind check
                } in tweakTheme { it.copy(cornerRadii = CornerRadius.RatioOfSpacing(0.1f)) }

                subtext("By clicking this box, I agree that PicMe may send\n me information about its services, offers, and my account by text message. Message frequency may vary and standard messaging rates may apply.")
            }

            suspend fun submit() {
                if (password().length < 8) {
                    passwordError set "Password must be at least 8 characters long"
                    return
                }
                session()?.authentication?.signUpUser(
                    emailOrPhoneNumber = emailOrPhone().lowercase(),
                    password = password(),
                    setTosRead = true,
                    referrerUserId = null,
                    mailFromAddress = "",
                    userName = ""
                ) ?: unauthApi().authenticationHandler.signUpUser(
                    emailOrPhoneNumber = emailOrPhone().lowercase(),
                    password = password(),
                    setTosRead = true,
                    referrerUserId = null,
                    mailFromAddress = "",
                    userName = "",
                    accessToken = { null }
                )
                showToast("Verification code sent")
                navigator.navigate(ConfirmEmail().also {
                    it.userPassword = password()
                })
            }

            val tf: TextField
            styledPassword {
                content bind password
                label = "Password"
                hint = "Password"
                error bind passwordError
                usePlatformSavedPasswords bind Property(false)
                tf = this.field
            }


            actionOrCancelSection(
                onCancel = { navigator.navigate(LoginOrSignUp()) },
                actionButton = {
                    importantButton(
                        text = "Continue",
                        field = tf,
                        onClick = ::submit
                    )
                }
            )

            eula("By clicking Continue, I agree to the PicMe")
        }
    }
}