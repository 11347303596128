package com.picme.views

import com.lightningkite.kiteui.Async
import com.lightningkite.kiteui.WaitGate
import com.lightningkite.kiteui.async
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.*
import com.picme.components.eula
import com.picme.components.importantButton
import com.picme.components.picmeIconDisplay

val createUserName = Property("")

suspend fun ViewWriter.askForName(): Async<String> {
    val gate = WaitGate()
    val overlay = overlayStack ?: throw Error("")
    val screen = with(overlay) {
        smallDialogWrapper(
            title = "What's your name?",
            dismiss = {
                createUserName set ""
                gate.permit = true
            },
            setup = {
                centered - text {
                    content = "Enter your name or generate a random one to remain anonymous."
                    align = Align.Center
                }

                space()

                val tf: TextField
                fieldSizedTheme - row {
                    spacing = 0.dp
                    padded - expanding - centered - textField {
                        content bind createUserName
                        hint = "Enter name"
                        tf = this
                    }

                    separator { }

                    space()
                    centered - ThemeDerivation {
                        it.copy(
                            outline = Color.white,
                            cornerRadii = CornerRadius.RatioOfSize(2f)
                        ).withoutBack
                    }.onNext - button {
                        spacing = 0.5.rem
                        icon {
                            source = PIcon.refresh
                        }
                        onClick {
                            createUserName set "Anonymous ${randomAnimals.random()}"
                        }
                    }
                    space()
                }

                importantButton(
                    Constant("Continue"),
                    field = tf,
                    onClick = {
                        if (createUserName().isNotBlank()) {
                            gate.permit = true
                        }
                    },
                    enabled = shared {
                        createUserName() != ""
                    }
                )

                eula("By clicking Continue, you agree to our")

            })
    }

    return async {
        gate.await()
        overlay.removeChild(screen)
        createUserName()
    }
}


fun ViewWriter.smallDialogWrapper(
    title: String? = null,
    dismiss: suspend () -> Unit,
    setup: ViewWriter.() -> Unit
): DismissBackground {
    return dismissBackground {
        padded - stack {
            centered - FatCardSemantic.onNext - sizeConstraints(maxWidth = 32.rem) - expanding - stack {
                spacing = 1.rem
                padded - padded - col {
                    centered - h4 {
                        ::exists { title != null }
                        content = title ?: ""
                    }
                    setup()
                }

                atTopEnd - ThemeDerivation {
                    it.copy(
                        cornerRadii = CornerRadius.ForceConstant(4.rem),
                        foreground = it.foreground.applyAlpha(0.5f),
                        spacing = it.spacing / 2
                    ).withoutBack
                }.onNext - button {
                    icon {
                        source = PIcon.close.copy(width = 1.5.rem, height = 1.5.rem)
                    }
                    onClick { dismiss() }
                }
            }
        }
    }
}

val randomAnimals = listOf(
    "mandrill",
    "gemsbok",
    "panther",
    "bumble bee",
    "lovebird",
    "ocelot",
    "budgerigar",
    "mole",
    "eagle owl",
    "crocodile",
    "badger",
    "guinea pig",
    "steer",
    "reindeer",
    "mouse",
    "guanaco",
    "monkey",
    "basilisk",
    "walrus",
    "blue crab",
    "hare",
    "coati",
    "grizzly bear",
    "gnu",
    "porcupine",
    "stallion",
    "lemur",
    "tapir",
    "polar bear",
    "addax",
    "meerkat",
    "eland",
    "cougar",
    "gila monster",
    "mule",
    "muskrat",
    "aoudad",
    "hamster",
    "ferret",
    "peccary",
    "baboon",
    "armadillo",
    "jackal",
    "kangaroo",
    "chameleon",
    "marmoset",
    "fawn",
    "chinchilla",
    "elephant",
    "anteater",
    "horse",
    "seal",
    "impala",
    "gazelle",
    "fox",
    "mountain goat",
    "ape",
    "panda",
    "puma",
    "squirrel",
    "waterbuck",
    "moose",
    "civet",
    "chicken",
    "jerboa",
    "bunny",
    "hyena",
    "musk deer",
    "chipmunk",
    "mustang",
    "doe",
    "ram",
    "sloth",
    "goat",
    "rabbit",
    "rooster",
    "cheetah",
    "wolf",
    "elk",
    "whale",
    "llama",
    "gorilla",
    "gopher",
    "giraffe",
    "warthog",
    "raccoon",
    "ibex",
    "iguana",
    "puppy",
    "hedgehog",
    "buffalo",
    "crow",
    "kitten",
    "otter",
    "mare",
    "orangutan",
    "dormouse",
    "highland cow",
    "chamois",
    "springbok",
    "porpoise",
    "lion",
    "rat",
    "wolverine",
    "ox",
    "wildcat",
    "bald eagle",
    "antelope",
    "alligator",
    "weasel",
    "cat",
    "turtle",
    "bighorn",
    "marten",
    "okapi",
    "vicuna",
    "newt",
    "bear",
    "canary",
    "ewe",
    "hog",
    "zebu",
    "snake",
    "yak",
    "finch",
    "alpaca",
    "tiger",
    "pronghorn",
    "snowy owl",
    "toad",
    "burro",
    "sheep",
    "skunk",
    "dromedary",
    "prairie dog",
    "frog",
    "deer",
    "capybara",
    "mongoose",
    "aardvark",
    "boar",
    "rhinoceros",
    "quagga",
    "argali",
    "beaver",
    "mink",
    "starfish",
    "ground hog",
    "hippopotamus",
    "bull",
    "opossum",
    "pony",
    "lynx",
    "lizard",
    "cow",
    "dingo",
    "coyote",
    "dog",
    "parrot",
    "leopard",
    "oryx",
    "zebra",
    "salamander",
    "camel",
    "bison",
    "silver fox",
    "ermine",
    "donkey",
    "woodchuck",
    "wombat",
    "lamb",
    "colt",
    "parakeet",
    "pig",
    "shrew",
    "chimpanzee",
    "octopus",
    "fish",
    "jaguar",
    "koala",
    "bat",
    "hartebeest",
)